import * as React from "react"
import PageLayout from "../../components/page-layout"
import Navbar from "../../components/navbar"
import { Link } from "gatsby"
import FadeIn from 'react-fade-in';

import "./style.css"

import ClappyCheeksImg from "./img/clappy-cheeks.gif"
import FbMsgsImg from "./img/fb-msgs.jpg"
import SuicuneImg from "./img/suicune.jpg"

class Project extends React.Component {
  render() {
    return (
      <div className="project-preview">
        <Link to={this.props.link}>
          <div className="block-25">
            <div className="project-img-container">
              <img className="project-img" src={this.props.img} />
            </div>
          </div>
          <div className="block-5" />
          <div className="block-70 project-text">
            <h3>{this.props.name}</h3>
            <p>
              <em>{this.props.date}</em>
            </p>
            <p>{this.props.description}</p>
          </div>
        </Link>
      </div>
    )
  }
}

const ProjectsPage = () => {
  return (
    <PageLayout title="projects | grac">
      <body id="projects-page" className="page">
        <div className="center-container">
          <Navbar />

          {/* PROJECTS */}
          <FadeIn>
            <div className="page-block">
              <h1 className="page-title">
                <span style={{ color: "var(--pink)" }}>P</span>
                <span style={{ color: "var(--yellow)" }}>R</span>
                <span style={{ color: "var(--green)" }}>O</span>
                <span style={{ color: "var(--lightblue)" }}>J</span>
                <span style={{ color: "var(--darkblue)" }}>E</span>
                <span style={{ color: "var(--green)" }}>C</span>
                <span style={{ color: "var(--yellow)" }}>T</span>
                <span style={{ color: "var(--purple)" }}>S</span>
              </h1>

              {/* <h2>👩🏻‍💻</h2> */}
              <p>this page is a work in progress👩🏻‍💻</p>
              <br/><hr/><br/>
            </div>
            {/* <div className="page-block">
              <Project
                name="Clappy Cheeks"
                date="2021"
                description="jolly szn B)"
                link="/projects/clappy-cheeks"
                img={ClappyCheeksImg}
              />

              <Project
                name="Facebook Messages Analysis"
                date="2020"
                description="Analyzing my FB Messaging Data"
                link="/play/fb-msgs"
                img={FbMsgsImg}
              />

              <Project
                name="Suicune"
                date="2017"
                description="Built a 3-foot model of one my favorite Pokemon :-)"
                link="/projects/suicune"
                img={SuicuneImg}
              />
            </div> */}
          </FadeIn>
        </div>
      </body>
    </PageLayout>
  )
}

export default ProjectsPage
